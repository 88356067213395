import React, { useState, useEffect } from 'react';
import './Sidebar.css';

export default function Sidebar({ props }) {
    return (<>
        <div className={`side-wrapper`}>
            <div onClick={() => {
                props.setCurrentView('Monitoring');
            }} className={`sidebar-div${props.currentView == 'Monitoring' ? '-highlight' : ''}`}>
                <img src="/icon/desktop_windows_FILL0_wght400_GRAD0_opsz48.svg" ></img>
                <p>모니터링</p>
            </div>
            <div onClick={() => {
                props.setCurrentView('DeviceManagement');
            }} className={`sidebar-div${props.currentView == 'DeviceManagement' ? '-highlight' : ''}`}>
                <img src="/icon/box_add_FILL0_wght400_GRAD0_opsz48.svg" ></img>
                <p>기기 관리</p>
            </ div>
            <div onClick={() => {
                props.setCurrentView('DistrictManagement');
            }} className={`sidebar-div${props.currentView == 'DistrictManagement' ? '-highlight' : ''}`}>
                <img src="/icon/room_preferences_FILL0_wght400_GRAD0_opsz48.svg" ></img>
                <p>공간 관리</p>
            </ div>
            {<div onClick={() => {
                props.setCurrentView('SiteManagement');
            }} className={`sidebar-div${props.currentView == 'SiteManagement' ? '-highlight' : ''}`}>
                <img src="/icon/add_business_FILL0_wght400_GRAD0_opsz48.svg" ></img>
                <p>지점 관리</p>
            </ div>}
            <div onClick={() => {
                props.setCurrentView('PermissionManagement');
            }} className={`sidebar-div${props.currentView == 'PermissionManagement' ? '-highlight' : ''}`}>
                <img src="/icon/lock_FILL0_wght400_GRAD0_opsz48.svg" ></img>
                <p>권한 관리</p>
            </ div>
            <div onClick={() => {
                props.setCurrentView('UserManagement');
            }} className={`sidebar-div${props.currentView == 'UserManagement' ? '-highlight' : ''}`}>
                <img src="/icon/person_FILL0_wght400_GRAD0_opsz48.svg" ></img>
                <p>계정 관리</p>
            </ div>
        </div>
    </>
    )
}