const { useState, useEffect } = require("react");

const { getDistricts } = require("../../util/districts");
const { getDevices, postDevice } = require("../../util/devices");

const Modal = require('react-modal');

export default function DeviceAddModal({ props }) {
    const deviceTypeList = ['문 제어기', 'PC 제어기', '월패드'];

    const [modalIsOpen, setModalIsOpen] = useState(false); // 새 구획 추가 여부

    const [deviceType, setDeviceType] = useState('undefined');
    const [name, setName] = useState('');

    const [districts, setDistricts] = useState([]); // 선택 가능한 distrcit 목록
    const [distrct, setDistrict] = useState('undefined'); // 선택한 district

    const [devices, setDevices] = useState([]); // 선택 가능한 device 목록

    const [openSensor, setOpenSensor] = useState('undefined'); // 선택한 문열림 센서
    const [openController, setOpenController] = useState('undefined'); // 선택한 문열림 제어기

    const [powerSensor, setPowerSensor] = useState('undefined'); // 선택한 전원 센서
    const [powerController, setPowerController] = useState('undefined'); // 선택한 전원 제어기

    const [cmsID, setCmsID] = useState(''); // 선택한 CMS 연동 아이디

    async function applyDoorContoller() {
        try {
            if (openSensor === 'undefined' || openController === 'undefined' || name === '') {
                alert('필요 정보가 없습니다');
                return;
            }
            const openSensorDevice = devices.filter((device) => {
                return device.id === openSensor;
            })

            if (openSensorDevice.length === 0) {
                console.log('센서 ID 없음');
                return;
            }
            const openSensorValueID = openSensorDevice[0].values.filter((value) => {
                return value.name === '문열림';
            })[0].id;

            const openControllerDevice = devices.filter((device) => {
                return device.id === openController;
            })

            if (openControllerDevice.length === 0) {
                console.log('제어기 ID 없음');
                return;
            }
            const openControllerValueID = openControllerDevice[0].values.filter((value) => {
                return value.name === '제어1';
            })[0].id;

            const result = await postDevice({
                name: name,
                site_id: props.currentSite.id,
                district_id: distrct,
                type: '문',
                values: [
                    {
                        name: '문열림 상태',
                        symbolic_id: openSensorValueID,
                        writable: false,
                        type: 'boolean'
                    },
                    {
                        name: '문열림 제어',
                        symbolic_id: openControllerValueID,
                        writable: true,
                        type: 'boolean'
                    }
                ]
            });

            if (result === undefined) {
                alert('생성에 실패하였습니다.');
                return;
            }
            props.setUpdateFlag(true);

            alert('생성되었습니다.');
            setModalIsOpen(false);

        } catch (e) {
            console.log(e);
        }
    }

    async function applyPCContoller() {
        try {
            if (powerSensor === 'undefined' || powerController === 'undefined' || name === '' || distrct === 'undefined') {
                alert('필요 정보가 없습니다');
                return;
            }

            const powerSensorDevice = devices.filter((device) => {
                return device.id === powerSensor;
            })
            if (powerSensorDevice.length === 0) {
                console.log('전력 센서가 없음');
                return;
            }
            const powerSensorValueID = powerSensorDevice[0].values.filter((value) => {
                return value.name === '소비전력';
            })[0].id;

            const powerControllerDevice = devices.filter((device) => {
                return device.id === powerController;
            })

            if (powerControllerDevice.length === 0) {
                console.log('전력 제어기가 없음');
                return;
            }

            const powerControllerValueID = powerControllerDevice[0].values.filter((value) => {
                return value.name === '제어1';
            })[0].id;

            const result = await postDevice({
                name: name,
                site_id: props.currentSite.id,
                district_id: distrct,
                type: 'PC',
                values: [
                    {
                        name: '소비 전력',
                        symbolic_id: powerSensorValueID,
                        writable: false,
                        type: 'number'
                    },
                    {
                        name: '전원 제어',
                        symbolic_id: powerControllerValueID,
                        writable: true,
                        type: 'boolean'
                    }
                ]
            });

            props.setUpdateFlag(true);


            if (result === undefined) {
                alert('생성에 실패하였습니다.');
                return;
            }

            alert('생성되었습니다.');
            setModalIsOpen(false);
        } catch (e) {
            console.log(e);
            alert('생성에 실패하였습니다.');
        }
    }

    async function applyWallpad() {
        try {
            const openControllerDevice = devices.filter((device) => {
                return device.id === openController;
            });

            if (openControllerDevice.length === 0) {
                console.log('제어기 ID 없음');
                return;
            }

            const openControllerValueID = openControllerDevice[0].values.filter((value) => {
                return value.name === '제어1';
            })[0].id;

            const result = await postDevice({
                name: name,
                district_id: distrct,
                site_id: props.currentSite.id,
                type: '월패드',
                values: [
                    {
                        name: '문열림 제어',
                        symbolic_id: openControllerValueID,
                        writable: true,
                        type: 'boolean'
                    },
                    {
                        name: 'CMSID',
                        writable: false,
                        type: 'number',
                        value: cmsID
                    }
                ]
            });

            if (result === undefined) {
                alert('생성에 실패하였습니다.');
                return;
            }

            props.setUpdateFlag(true);
            setModalIsOpen(false);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (props.currentSite === undefined) return;

        getDistricts({
            site_id: props.currentSite.id
        }).then((districts) => {
            setDistricts(districts)
        });

        getDevices({
            site_id: props.currentSite.id
        }).then((devices) => {
            setDevices(devices);
        });

    }, [props.currentSite]);

    function selectDistrict(e) {
        setDistrict(e.target.value);
    }


    const modalStyle = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
        },
        content: {
            width: '500px',
            height: '500px',
            margin: 'auto',
            backgroundColor: 'rgb(255, 255, 255)',
            borderRadius: '10px',
            padding: '10px',
            zIndex: 1000
        }
    };

    return (<>
        <div className='management-add-button' onClick={() => { setModalIsOpen(!modalIsOpen) }}>
            <p>{!modalIsOpen ? '기기 생성' : ''}</p>
        </div>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => { setModalIsOpen(false) }}
            style={modalStyle}
            contentLabel="기기 생성창"
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>기기 생성하기</h2>
                <button onClick={() => { setModalIsOpen(false) }}>X</button>
            </div>
            <div className='management-modal'>
                <div className='management-header'>
                    <div className='management-header-item'>기기 종류</div>
                    <div className='management-header-item'>지점</div>
                    <div className='management-header-item'>공간</div>

                </div>
                <div className='management-body'>
                    <div className='management-body-item'>
                        <select onChange={(e) => { setDeviceType(e.target.value) }} value={deviceType}>
                            <option value={'undefined'}>선택</option>
                            {
                                deviceTypeList.map((deviceType) => {
                                    return <option key={deviceType} value={deviceType}>{deviceType}</option>
                                })
                            }
                        </select>
                    </div >
                    <div className='management-body-item'>
                        {props.currentSite.name}
                    </div >
                    <div className='management-body-item'>
                        <select onChange={selectDistrict} value={distrct}>
                            <option value={'undefined'}>선택</option>
                            {
                                districts && districts.map((district) => {
                                    return <option key={district.id} value={district.id}>{district.name}</option>
                                })
                            }
                        </select>
                    </div >
                </div>
            </div>
            {
                deviceType === '문 제어기' &&
                <div className='management-modal'>
                    <div className='management-header'>
                        <div className='management-header-item'>이름</div>
                        <div className='management-header-item'>문열림 센서</div>
                        <div className='management-header-item'>문열림 제어기</div>
                    </div>
                    <div className='management-body'>
                        <div className='management-body-item'>
                            <input type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
                        </div >
                        <div className='management-body-item'>
                            <select onChange={(e) => { setOpenSensor(e.target.value) }} value={openSensor}>
                                <option value={undefined}>선택</option>
                                {
                                    devices &&
                                    devices
                                        .filter((device) => {
                                            return device.type === '문열림 센서';
                                        })
                                        .sort((a, b) => a.name < b.name ? -1 : 1)
                                        .map((d) => {
                                            return <option key={d.id} value={d.id}>{d.name}</option>
                                        })
                                }
                            </select>
                        </div>
                        <div className='management-body-item'>
                            <select onChange={(e) => { setOpenController(e.target.value) }} value={openController}>
                                <option value={undefined}>선택</option>
                                {
                                    devices &&
                                    devices
                                        .filter((device) => {
                                            return device.type === 'General Controller';
                                        })
                                        .sort((a, b) => a.name < b.name ? -1 : 1)
                                        .map((d) => {
                                            return <option key={d.id} value={d.id}>{d.name}</option>
                                        })
                                }
                            </select>
                        </div>
                    </div >
                    <p>&nbsp;</p>
                    <div className='management-add-button' onClick={applyDoorContoller}>
                        <p>생성 하기</p>
                    </div>
                </div>
            }
            {
                deviceType === 'PC 제어기' &&
                <div className='management-modal'>
                    <div className='management-header'>
                        <div className='management-header-item'>이름</div>
                        <div className='management-header-item'>전력 센서</div>
                        <div className='management-header-item'>버튼 제어기</div>
                    </div>
                    <div className='management-body'>
                        <div className='management-body-item'>
                            <input type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
                        </div >
                        <div className='management-body-item'>
                            <select onChange={(e) => { setPowerSensor(e.target.value) }} value={powerSensor}>
                                <option value={undefined}>선택</option>
                                {
                                    devices &&
                                    devices
                                        .filter((device) => {
                                            return device.type === '콘센트';
                                        })
                                        .sort((a, b) => a.name < b.name ? -1 : 1)
                                        .map((d) => {
                                            return <option key={d.id} value={d.id}>{d.name}</option>
                                        })
                                }
                            </select>
                        </div>
                        <div className='management-body-item'>
                            <select onChange={(e) => { setPowerController(e.target.value) }} value={powerController}>
                                <option value={undefined}>선택</option>
                                {
                                    devices &&
                                    devices
                                        .filter((device) => {
                                            return device.type === 'General Controller';
                                        })
                                        .sort((a, b) => a.name < b.name ? -1 : 1)
                                        .map((d) => {
                                            return <option key={d.id} value={d.id}>{d.name}</option>
                                        })
                                }
                            </select>
                        </div>
                    </div >
                    <p>&nbsp;</p>
                    <div className='management-add-button' onClick={applyPCContoller}>
                        <p>생성 하기</p>
                    </div>
                </div>
            }
            {
                deviceType === '월패드' &&
                <div className='management-modal'>
                    <div className='management-header'>
                        <div className='management-header-item'>이름</div>
                        <div className='management-header-item'>문열림 제어기</div>
                        <div className='management-header-item'>CMS 연동 아이디</div>
                    </div>
                    <div className='management-body'>
                        <div className='management-body-item'>
                            <input type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
                        </div >
                        <div className='management-body-item'>
                            <select onChange={(e) => { setOpenController(e.target.value) }} value={openController}>
                                <option value={undefined}>선택</option>
                                {
                                    devices &&
                                    devices
                                        .filter((device) => {
                                            return device.type === 'General Controller';
                                        })
                                        .sort((a, b) => a.name < b.name ? -1 : 1)
                                        .map((d) => {
                                            return <option key={d.id} value={d.id}>{d.name}</option>
                                        })
                                }
                            </select>
                        </div>
                        <div className='management-body-item'>
                            <input type='text' value={cmsID} onChange={(e) => { setCmsID(e.target.value) }} />
                        </div >
                    </div>
                    <p>&nbsp;</p>
                    <div className='management-add-button' onClick={applyWallpad}>
                        <p>생성 하기</p>
                    </div>
                </div>
            }
        </Modal>
    </>)
}