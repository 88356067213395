import axios from 'axios';

export async function getSites() {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/sites`, {
            withCredentials: true,
        });

        return res.data.sort((a, b) => {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            else return 0;
        });
    } catch (e) {
        console.log('사이트 목록 불러오기 실패');
        return undefined;
    }
}

export async function postSite(data) {
    try {
        const body = {};
        for (const key in data) {
            if (data[key])
                body[key] = data[key];
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/sites`, body, {
            withCredentials: true,
        });

        return '사이트 추가 성공';
    } catch (e) {
        console.error(e.response.data);
        return e.response.data ? e.response.data : '사이트 추가 실패';
    }
}

export async function putSite(id, data) {
    try {
        const body = {};
        for (const key in data) {
            if (data[key])
                body[key] = data[key];
        }

        await axios.put(`${process.env.REACT_APP_API_URL}/sites/${id}`, body, {
            withCredentials: true,
        });

        return '사이트 변경 성공';
    } catch (e) {
        console.error(e.response.data);
        return e.response.data ? e.response.data : '사이트 변경 실패';
    }
}

export async function deleteSite(id) {
    try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/sites/${id}`, {
            withCredentials: true,
        });

        return '사이트 삭제 성공';
    } catch (e) {
        console.error(e);
        return '사이트 삭제 실패';
    }
}