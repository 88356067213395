import React, { useState, useEffect } from 'react';

import { postSite } from '../../util/sites';

export default function AddSite(props) {

    const [name, setName] = useState('');

    async function applyBtn() {
        try {
            if (name === '') { // 이름을 입력 안했으면
                alert('이름을 입력해주세요.');
                return;
            }
            const message = await postSite({
                name: name,
            });
            props.update();
            alert(message);
        } catch (e) {
            console.log(e);
        }
    }
    async function cancleBtn() {
        props.setNewSite(false);
    }

    return (<>
        <div className='management-body'>
            <div className='management-body-item'>
                <input placeholder='매장이름 입력' className='add-site-text-input' type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
            </div>
            <div className='management-body-item'>
                <button className='management-body-item-button' onClick={applyBtn}>추가</button>
                <button className='management-body-item-delete-button' onClick={cancleBtn} >취소</button>
            </div>
        </div>
    </>)
}