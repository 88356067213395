import React, { useState, useEffect } from 'react';
import { postDevice } from '../../util/devices';

export default function CameraAddItem(props) {
    const [name, setName] = useState(undefined);
    const [nvrID, setNvrID] = useState(undefined);
    const [url, setUrl] = useState(undefined);
    const [siteID, setSiteID] = useState(undefined);
    const [districtID, setDistrictID] = useState(undefined);
    const [districts, setDistricts] = useState(undefined);

    useEffect(() => {
        if (siteID !== undefined) {
            setDistricts(props.districts.filter(district => district.site_id === siteID));
        }
    }, [siteID]);

    async function applyBtn() {
        try {
            if (name === undefined || name === '') {
                alert('이름을 입력해주세요.');
                return;
            }
            if (nvrID === undefined || nvrID === '녹화기 선택') {
                alert('녹화기를 선택해주세요.');
                return;
            }
            if (url === undefined || url === '') {
                alert('URL을 입력해주세요.');
                return;
            }
            if (siteID === undefined || siteID === '선택') {
                alert('지점을 선택해주세요.');
                return;
            }
            if (districtID === undefined || districtID === '선택') {
                alert('공간을 선택해주세요.');
                return;
            }

            const device = {
                name: name,
                type: 'channel',
                district_id: districtID,
                site_id: siteID,
                handling_function: 'iot-handler-nvr',
                values: [
                    {
                        name: 'nvr_id',
                        value: nvrID,
                        type: 'string',
                    },
                    {
                        name: 'url',
                        value: url,
                        type: 'string',
                    }
                ]
            }

            const res = await postDevice(device);
            if (res === undefined) {
                alert('생성에 실패하였습니다.');
                return;
            } else {
                alert('생성되었습니다.');
            }
        } catch (e) {
            alert('생성에 실패하였습니다.');
        }
        props.setUpdateFlag(true);
    }

    async function deleteBtn() {
        try {

        } catch {
            alert('삭제에 실패하였습니다.');
        }
    }


    return (<>
        <div className='management-body'>
            <div className='management-body-item'>
                <input type='text' value={name} onChange={(e) => { setName(e.target.value) }} placeholder='신규 채널 등록' />
            </div >
            <div className='management-body-item'>
                <select value={nvrID} onChange={(e) => { setNvrID(e.target.value) }} >
                    <option value={undefined}>녹화기 선택</option>
                    {
                        props.devices && props.devices.map((device) => {
                            if (device.type === 'NVR')
                                return <option key={device.id} value={device.id}>{device.name}</option>
                        })
                    }
                </select>
            </div>
            <div className='management-body-item'>
                <input type='text' value={url} onChange={(e) => { setUrl(e.target.value) }} />
            </div>
            <div className='management-body-item'>
                <select value={siteID} onChange={(e)=>{ setSiteID(e.target.value)}}>
                    <option value={undefined}>선택</option>
                    {
                        props.sites && props.sites.map((site) => {
                            return <option key={site.id} value={site.id}>{site.name}</option>
                        })
                    }
                </select>
            </div>
            <div className='management-body-item'>
                {
                    <select value={districtID} onChange={(e)=>{setDistrictID(e.target.value)}}>
                        <option value={undefined}>선택</option>
                        {
                            districts && districts.map((district) => {
                                return <option key={district.id} value={district.id}>{district.name}</option>
                            })
                        }
                    </select>
                }
            </div>
            <div className='management-body-item'>
                <button className='management-body-item-button' onClick={applyBtn}>생성</button>
                {/* <button className='management-body-item-delete-button' onClick={deleteBtn}>삭제</button> */}
            </div>
        </div>
    </>)
}