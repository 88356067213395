import React, { useState, useEffect } from 'react';

export default function PermissionManagementTableItem({ props }) {

    const [newTargetUser, setNewTargetUser] = useState(Object.assign({}, props.targetUser));
    const [scope, setScope] = useState(props.id);

    function changeRW(e) {
        switch (e.target.value) {
            case 'r':
                newTargetUser.permission[props.resource][props.id]['read'] = true;
                newTargetUser.permission[props.resource][props.id]['write'] = false;
                break;
            case 'w':
                newTargetUser.permission[props.resource][props.id]['read'] = false;
                newTargetUser.permission[props.resource][props.id]['write'] = true;
                break;
            case 'rw':
                newTargetUser.permission[props.resource][props.id]['read'] = true;
                newTargetUser.permission[props.resource][props.id]['write'] = true;
                break;
            case '':
                newTargetUser.permission[props.resource][props.id]['read'] = false;
                newTargetUser.permission[props.resource][props.id]['write'] = false;
                break;
            default:
                break;
        }
        props.setTargetUser(newTargetUser);
    }
    function deletePermission() {
        delete newTargetUser.permission[props.resource][props.id];
        props.setTargetUser(newTargetUser);
    }

    useEffect(() => {
        if (props.id !== "*")  {
            const filteredScope = props[`${props.resource}s`].filter((s) => s.id === props.id);
            if (filteredScope.length > 0) {
                if (filteredScope[0].name) {
                    setScope(filteredScope[0].name);
                }
            }
        }
    });

    return (<>
        <div className='management-body'>
            <div className='management-body-item'>{scope}</div>
            <div className='management-body-item'>
                {
                    props.targetUser && props.targetUser.permission && props.targetUser.permission[props.resource] && props.targetUser.permission[props.resource][props.id] &&
                    <select onChange={changeRW} value={`${props.targetUser.permission[props.resource][props.id]['read'] ? 'r' : ''}${props.targetUser.permission[props.resource][props.id]['write'] ? 'w' : ''}`} >
                        <option value={'r'}>읽기</option>
                        <option value={'w'}>쓰기</option>
                        <option value={'rw'}>읽쓰기</option>
                        <option value={''}>없음</option>
                    </select>
                }
            </div>
            <div className='management-body-item'>
                <button className='management-body-item-delete-button' onClick={deletePermission}>삭제</button>
            </div>
        </div>
    </>)
}