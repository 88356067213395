import axios from 'axios';

export async function getUsers() {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/users`, {
            withCredentials: true,
        });

        return res.data.sort((a, b) => {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            else return 0;
        });
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

export async function putUser(id, data) {
    try {
        const formData = new FormData();
        for (const key in data) {
            if (data[key])
                formData.append(key, data[key]);
        }

        const res = await axios.put(`${process.env.REACT_APP_API_URL}/users/${id}`, formData, {
            withCredentials: true,
        });

        return '사용자 변경 성공';
    } catch (e) {
        console.error(e.response.data);
        return e.response.data ? e.response.data : '사용자 변경 실패';
    }
}

export async function deleteUser(id) {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/users/${id}`, {
            withCredentials: true,
        });

        return '사용자 삭제 성공';
    } catch (e) {
        console.error(e.response.data);
        return e.response.data ? e.response.data : '사용자 삭제 실패';
    }
}

export async function postUser(data) {
    let res;
    try {
        const formData = new FormData();
        for (const key in data) {
            if (data[key])
                formData.append(key, data[key]);
        }

        res = await axios.post(`${process.env.REACT_APP_API_URL}/users`, formData, {
            withCredentials: true,
        });

        return '계정추가 성공';
    } catch (e) {
        console.error(e.response.data);
        return e.response.data ? e.response.data : '사용자 추가 실패';
    }
}