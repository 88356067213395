import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import "./LoginForm.css";

const API_URL = `${process.env.REACT_APP_API_URL}/login`;

export default function LoginForm() {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const account = new FormData();
        account.append('id', username);
        account.append('password', password);

        try {
            const response = await axios.post(API_URL, account, { withCredentials: true });
            if (response.status === 200) {
                navigate('/dashboard');
            } else {
                setError('로그인 실패');
            }
        } catch (error) {
            setError('로그인 실패');
        }
    };

    return (<>
        <div className='login-wrapper'>
            <p className='login_p'>로그인</p>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="username" className='id_label'>아이디:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUsernameChange}
                        className='id_input'
                    />
                </div>
                <div>
                    <label htmlFor="password" className='password_lable'>비밀번호:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        className='password_input'
                    />
                </div>
                <button type="submit" className='login_btn'>로그인</button>
                {error && <div className='login_error'>{error}</div>}
            </form>
        </div>

    </>)
}