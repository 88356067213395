import { useEffect, useState } from "react";
import axios from "axios";
import { putValue } from "../../../util/deviceValues";
import { getDevice } from "../../../util/devices"
import { putValueTuya, putDeviceValue } from "../../../util/devicesTuya";

export default function PC({ props }) {
    const [sensor, setSensor] = useState(undefined);
    const [control, setControl] = useState(undefined);

    async function powerButton() {
        try {
            console.log(control)

            //tuya or lifesmart 검증
            let partner = 'lifeSmart';
            const powerController = await getDevice(control.device_id);
            if (powerController && powerController.data && powerController.data.partner_name) {
                partner = powerController.data.partner_name;
            }

            if (partner === "tuya") {
                const isPush = true;

                // const buttonPushRes = await putValueTuya(control.device_id, isPush, control.id, control.name, props.currentUser.id);
                const buttonPushRes = await putDeviceValue({
                    deviceId: control.device_id,
                    valueId: control.id,
                    newValue: isPush,
                    valueSymbolicId: control.symbolic_id,
                });

                // const buttonReleaseRes = await putValueTuya(control.device_id, !isPush, control.id, control.name, props.currentUser.id);
                const buttonReleaseRes = await putDeviceValue({
                    deviceId: control.device_id,
                    valueId: control.id,
                    newValue: !isPush,
                    valueSymbolicId: control.symbolic_id,
                });

            } else {
                await putValue(control.device_id, control.id, true);
                const result = await putValue(control.device_id, control.id, false);

                if (undefined === result) {
                    alert('전원 제어 실패');
                    return;
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        props.device.values.forEach((value) => {
            if (value.name === '소비 전력') {
                const sensorValueID = value.symbolic_id;
                props.devices.forEach((d) => {
                    d.values.forEach((v) => {
                        if (v.id === sensorValueID) {
                            setSensor(v);
                            console.log(v);
                        }
                    });
                });

            } else if (value.name === '전원 제어') {
                const controlValueID = value.symbolic_id;
                props.devices.forEach((d) => {
                    d.values.forEach((v) => {
                        if (v.id === controlValueID) {
                            setControl(v);
                        }
                    });
                });
            }
        });

    }, [props.devices])

    return (<>
        <div className='device-card'>
            <div className='device-card-wrapper-vertical'>
                {
                    sensor && <img src={sensor.value > 10 ? "/icon/desktop_windows_FILL0_wght400_GRAD0_opsz48.svg" : "/icon/desktop_access_disabled_FILL0_wght400_GRAD0_opsz48.svg"} />
                }

            </div>
            <div className='device-card-wrapper-vertical'>
                {props.device.name}
                {
                    sensor && <p>소비전력: {sensor.value} W</p>
                }
            </div>
            <div className='device-card-wrapper-vertical'>
                <div className="sidebar-div" onClick={powerButton} >
                    <img src="/icon/power_settings_new_FILL0_wght400_GRAD0_opsz48.svg" />
                </div>
            </div>

        </div>
    </>);
}
