import "./VideoView/VideoView.css";

import { useState, useRef, useEffect } from "react";
import { getDevice } from "../../../util/devices";

import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';


// import css
export default function Channel(props) {
    const videoRef = useRef(undefined);
    const playerRef = useRef(undefined);
    const initRef = useRef(false);

    async function init() {
        try {
            console.log('플레이어 초기화');
            const nvrID = props.device.values.filter((value) => value.name === 'nvr_id')[0].value;
            const res = await getDevice(nvrID);
            const values = {};
            for (const value of res.data.values) {
                values[value.name] = value;
            }

            const player = videojs(videoRef.current, {
                autoplay: 'true',
                sources: {
                    src: 'https://' + values['ddns'].value + ':' + values['port'].value + '/live/'+ props.device.id,
                    type: 'application/x-mpegURL',
                },
                techTimeout: 5000,
            });
            playerRef.current = player;
            // 정지시 재생
            player.on('pause', () => {
                console.log('플레이어 정지');
            });
            player.on('error', (e) => {
                console.log('플레이어 에러');
                player.reset();
            });
        } catch (e) {
            console.log(`채널 렌더링 실패: ${e}`);
        }
    }

    useEffect(() => {
       if( initRef.current === false ) {
        initRef.current = true;
        init();
       }

        return () => {
            console.log('플레이어 정리');
            const player = playerRef.current;
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = undefined;
            }
        }

    }, []);

    return (<>
        <div className="video-view-card">
            <div className="video-view-header">
                <div>{props.device && props.device.name}</div>
            </div>
            <div className="video-view-body">
                <div className="video-monitor">
                    <video ref={videoRef} className="video-js" />
                </div>
            </div>
        </div>
    </>)
}