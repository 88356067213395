import axios from 'axios';
import { api, tuyaApi } from './axios-global-instance';
import { getDevice } from './devices';
import { getLogin } from './login';
/**
 * # putValueTuya
 * ! deprecated
 * - 파트너센터와 다르게 보내고 있다. 
 * - 추후 V2로 변경할 예정
 * 24년 4월 23일 기준 사용하는 곳 없음. 제거할 예정.
 * 
 * @param {string} deviceId 
 * @param {any} value 
 * @param {string} valueId 
 * @param {string} valueName 
 * @param {string} userId 
 * @returns {Promise} 
 */
export const putValueTuya = async (deviceId, value, valueId, valueName, userId) => {
    try {
        const res = await tuyaApi.put(`/device/${deviceId}`, {
            value: value,
            valueId: valueId,
            name: valueName,
            userId: userId,
        });

        console.log(res?.data);

        return res?.data;
    } catch (error) {
        console.log(error);
    }
}

/**
 * Symbolic ID를 사용하여 원본 value 값을 가져올 때 사용한다
 * 
 * @param {string} symbolicId
 */
export async function getDeviceValueByID(symbolicId) {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/device/undefined/value/${symbolicId}`, {
            withCredentials: true,
        });

        return {
            result: true,
            value: res.data,
        }
    } catch (e) {
        console.log(`디바이스 불러오기 실패: ${e}`);
        return {
            result: false,
            message: '디바이스 불러오기 실패',
        }
    }
}

/** 
 * # 장치를 조작할 때 사용한다 
 * - 파트너센터와 동일한 로직을 사용한다
 * 
 * @param {
 * deviceId: string,
 * valueId: string,
 * newValue: any,
 * valueSymbolicId: string,
 * } 
*/
export async function putDeviceValue({ deviceId, valueId, newValue, valueSymbolicId }) {
    try {
        // symbolic_id가 가르키는 value 상태 값을 가져온다.
        const controllerValueRes = await getDeviceValueByID(valueSymbolicId || valueId);

        // symbolic_id에 연동된 device_id를 사용한다.
        const tuyaDeviceValue = controllerValueRes.value
        const tuyaDeviceRes = await getDevice(tuyaDeviceValue.device_id)
        const tuyaDevice = tuyaDeviceRes?.data;

        if (tuyaDevice && tuyaDevice.partner_name === "tuya") {
            console.log('%cThis is tuya', 'background-color: yellow;');
            // Tuya 기기 조작 로그를 쌓기 위한 user 정보를 가져온다.
            const user = await getLogin();

            const res = await tuyaApi.put(`/device/${tuyaDevice.id}`, {
                name: tuyaDeviceValue.name,
                value: newValue,
                valueId: tuyaDeviceValue.id,
                originalDeviceId: deviceId,
                userId: user?.id,
            })

            return {
                result: true,
                value: res?.data,
            }
        } else {
            // 기존 람다 서버의 경우
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/device/undefined/value/${valueSymbolicId || valueId}`, { value: newValue }, {
                withCredentials: true,
            });
            return {
                result: true,
                value: res.data,
            }
        }
    } catch (e) {
        console.error(e.response.data);
        if (e?.response?.data?.resultMsg) {
            alert(e?.response?.data?.resultMsg);
        }

        return {
            result: false,
            message: '값 변경 실패',
        };
    }
}

/**
 * # putDeviceMapping
 * - 기기 연동을 위한 API
 * 
 * @param {string} deviceId 
 * @param {string} concentDeviceId 
 * @param {string} deviceType 
 * @returns {Promise}
 */
export const putDeviceMapping = async (deviceId, concentDeviceId) => {
    try {
        const res = await tuyaApi.put(`/device/mapping/${deviceId}`, {
            device_id: concentDeviceId,
            type: '소비 전력',
        });

        return {
            result: true,
            data: res.data,
        }
    } catch (error) {
        console.log(error);
        return {
            result: false,
            message: '기기 연동 실패',
        }
    }
}