import React, { useState, useEffect } from 'react';
import { putDevice, deleteDevice } from '../../util/devices';
import { putValue, postValue } from '../../util/deviceValues';
import { doorTypes } from '../../types/door-types';
import { concentTypes } from '../../types/concent-types';
import { putDeviceMapping } from '../../util/devicesTuya';

export default function DeviceManagementItem(props) {
    const [name, setName] = useState(props.device.name);
    const oldName = props.device.name;

    const [doorType, setDoorType] = useState(props.device.door_type || 'none');
    const [concentType, setConcentType] = useState(props.device.concent_type || 'none');

    const [siteID, setSiteID] = useState(undefined);

    const [districtID, setDistrictID] = useState(props.device.district_id);
    const oldDistrictID = props.device.district_id;

    const [districts, setDistricts] = useState([]); // 선택 가능한 district 목록

    const [hide, setHide] = useState(undefined);

    const [concentId, setConcentId] = useState('');

    function districtChange(e) {
        setDistrictID(e.target.value);
    }

    function siteChange(e) {
        setSiteID(e.target.value);
    }

    async function applyBtn() {
        try {
            const body = {
                id: props.device.id,
                name: name,
                district_id: districtID,
                site_id: siteID,
            }
            if (doorType !== 'none') {
                body.door_type = doorType;
            } else if (concentType !== 'none') {
                body.concent_type = concentType;
            }

            const result = await putDevice(body);
            props.setUpdateFlag(true);

            if (result === undefined) {
                alert('변경에 실패하였습니다.');
                return;
            } else {
                setName(props.device.name);
                alert('변경되었습니다.');
            }
        } catch {
            alert('변경에 실패하였습니다.');
        }
    }

    async function deleteBtn() {
        try {
            const result = await deleteDevice(props.device.id);
            props.setUpdateFlag(true);

            if (result === undefined) {
                alert('삭제에 실패하였습니다.');
                return;
            } else {
                alert('삭제되었습니다.');
            }
        } catch {
            alert('삭제에 실패하였습니다.');
        }
    }

    async function hideUpdate(e) {
        try {

            setHide(e.target.checked);
            const hideValue = props.device.values.filter(value => value.name === 'hide');
            if (hideValue.length === 0) {
                await postValue(props.device.id, {
                    name: 'hide',
                    value: e.target.checked,
                });
            } else {
                await putValue(props.device.id, hideValue[0].id, e.target.checked);
            }
            props.setUpdateFlag(true);
        } catch { }
    }



    useEffect(() => { // 최초 실행 로직
        if (districtID !== undefined) {
            setDistricts(props.districts.filter(district => district.site_id === siteID));
            setSiteID(props.districts.filter(district => district.id === districtID)[0].site_id);
        }

        const findHide = props.device.values.filter(value => value.name === 'hide');
        if (findHide.length === 0) {
            setHide(false);
        } else {
            setHide(findHide[0].value === 'true');
        }
    }, []);

    useEffect(() => {
        if (siteID !== undefined) {
            setDistricts(props.districts.filter(district => district.site_id === siteID));
        }
    }, [siteID]);

    //#region 콘센트 연동
    async function handleChangeConcent(e) {
        const newConcent = e.target.value === 'none' ? '' : e.target.value;
        const res = await putDeviceMapping(props.device.id, newConcent);

        if (res.result === true) {
            setConcentId(newConcent);
            alert('연동되었습니다.');
        } else {
            alert('연동에 실패하였습니다.');
        }
    };

    useEffect(() => {
        if (!props.concents) {
            return;
        }

        const wattSymbolicId = props.device.values?.find(value => value.name === '소비 전력')?.symbolic_id
        if (wattSymbolicId) {
            let isFound = false;
            for (const concent of props.concents) {
                for (const value of Object.values(concent.values)) {
                    if (value.id === wattSymbolicId) {
                        setConcentId(concent.id);
                        isFound = true;
                        break;
                    }
                }
                if (isFound) break;
            }
        }
    }, [props.concents])
    //#endregion

    return (<>
        <div className='management-body'>
            <div className='management-body-item'>
                <input type='checkbox' checked={hide} onChange={hideUpdate} />
            </div >
            <div className='management-body-item'>
                <input type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
            </div >
            <div className='management-body-item'>
                {props.device.type}
            </div >
            <div className='management-body-item'>
                {props.device.type === '문' &&
                    <select onChange={(e) => setDoorType(e.target.value)} value={doorType}>
                        <option value='none'>선택</option>
                        {
                            Object.values(doorTypes).map((doorType) => {
                                return <option key={doorType} value={doorType}>{doorType}</option>
                            })
                        }
                    </select>
                }
                {props.device.type === '콘센트' &&
                    <select onChange={(e) => setConcentType(e.target.value)} value={concentType}>
                        <option value='none'>선택</option>
                        {
                            Object.values(concentTypes).map((concentType) => {
                                return <option key={concentType} value={concentType}>{concentType}</option>
                            })
                        }
                    </select>
                }
            </div >
            <div className='management-body-item'>
                {Object.hasOwn(props.device, 'partner_name') && props.device.partner_name}
            </div >
            <div className='management-body-item'>
                {(props.device.type === 'TV' || props.device.type === '에어컨') &&
                    <select onChange={handleChangeConcent} value={concentId}>
                        <option value={'none'}>선택</option>
                        {
                            props.concents && props.concents.map((concent) => {
                                return <option key={concent.id} value={concent.id}>{concent.name}</option>
                            })
                        }
                    </select>
                }
            </div>
            <div className='management-body-item'>
                <select onChange={siteChange} value={siteID}>
                    <option value={undefined}>선택</option>
                    {
                        props.sites && props.sites.map((site) => {
                            return <option key={site.id} value={site.id}>{site.name}</option>
                        })
                    }
                </select>
            </div>
            <div className='management-body-item'>
                {
                    <select onChange={districtChange} value={districtID}>
                        <option value={undefined}>선택</option>
                        {
                            districts && districts.map((district) => {
                                return <option key={district.id} value={district.id}>{district.name}</option>
                            })
                        }
                    </select>
                }
            </div>
            <div className='management-body-item'>
                <button className='management-body-item-button' onClick={applyBtn}>적용</button>
                <button className='management-body-item-delete-button' onClick={deleteBtn}>삭제</button>
            </div>
        </div>
    </>)
}