import axios from 'axios';

export async function getValues(device_id, value_id) {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/devices/${device_id}/values/${value_id}`, {
            withCredentials: true,
        });

        return res.data;
    } catch (e) {
        console.error(e.response.data);
        return undefined;
    }
}

/** 장치를 조작할 때 사용한다 
 * 
 * @param {string} device_id
 * @param {string} value_id
 * @param {string} value
 * @param {'lifesmart' | 'tuya'} partnerName
*/
export async function putValue(device_id, value_id, value, partnerName = 'lifesmart') {
    try {
        let res;
        if (partnerName === 'tuya') {
            console.log('%cThis is tuya', 'background-color: yellow; font-size: 2rem;')
        }

        res = await axios.put(`${process.env.REACT_APP_API_URL}/devices/${device_id}/values/${value_id}`, {
            value: value,
        }, {
            withCredentials: true,
        });

        return res;
    } catch (e) {
        console.error(e.response.data);
        return undefined;
    }
}

/** CameraItem, 기기 관리 페이지에서 장치를 숨기거나 객체로 value를 넣을 때 사용한다 
 * values 파라미터가 객체로 들어온다
 * 장치를 숨길 때 hide 이름의 value 값을 boolean으로 전달한다.
 * 
 * @param {string} device_id
 * @param {object} values
 * @param {'lifesmart' | 'tuya'} partnerName
*/
export async function postValue(device_id, values, partnerName = 'lifesmart') {
    try {
        let res;
        if (partnerName === 'tuya') {
            console.log('%cThis is tuya', 'background-color: yellow; font-size: 2rem;')
        }

        res = await axios.post(`${process.env.REACT_APP_API_URL}/devices/${device_id}/values`, values, {
            withCredentials: true,
        });

        return res;
    } catch (e) {
        console.error(e.response.data);
        return undefined;
    }
}
