import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401 || error.response.status === 404 && error.response.data === '세션이 없습니다') {
            // const redirect = location.pathname + location.search;
            // location.href = '/login' + (redirect.length > 0 ? `?redirect=${redirect}` : '');
            window.location.href = '/login';

            delete tuyaApi.defaults.headers['user-id']
        }

        console.log(error);
        return Promise.reject(error);
    }
);

// * Tuya Api ------------------------------------------------ 

const tuyaApi = axios.create({
    baseURL: process.env.REACT_APP_TUYA_URL,
    withCredentials: true,
    headers: {
        "authorization": process.env.REACT_APP_TUYA_AUTH,
    }
});

tuyaApi.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);

export { api, tuyaApi };