import './DistrictButton.css'

export default function DistrictButton({props}) {


    function selectCurrentDistrict() {
        props.setCurrentDistrict(props.district);
    }

    return (<>
        {
            props.currentDistrict ?
                <button onClick={selectCurrentDistrict} className={`district-button${props.currentDistrict.id == props.district.id ? '-highlight' : ''}`}>
                    <p>{props.district.name}</p>
                </button>
                :
                <button onClick={selectCurrentDistrict} className={'district-button'}>
                    <p>{props.district.name}</p>
                </button>
        }
    </>)
}