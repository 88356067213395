import React, { useState, useEffect, useRef } from 'react';
import { putDistrict, deleteDistrict } from '../../util/districts';


export default function DistrictManagementItem({ props }) {
    const itemRef = useRef(null);
    const [name, setName] = useState(props.district.name);

    async function applyBtn() {
        try {
            const body = {};
            if (name !== props.district.name) { // 이름이 바뀌었다면
                body.name = name;
            }
            if (Object.keys(body).length === 0) {
                alert('변경할 내용이 없습니다.');
                return;
            }
            const message = await putDistrict(props.district.id, body);
            alert(message);
        } catch (e) {
        }
    }
    async function removeDistrict() {
        try {
            const message = await deleteDistrict(props.district.id);
            alert(message);
        } catch (e) {
        }
    }

    useEffect(() => {
        if (itemRef.current.offsetWidth > props.width)
            props.setWidth(itemRef.current.offsetWidth);
        else {
            itemRef.current.style.width = `${props.width}px`;
        }
    }, [props.width]);

    return (<>
        <div className='management-body'>
            <div className='management-body-item' ref={itemRef} >
                <input type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
            </div>
            <div className='management-body-item'>{props.currentSite.name}</div>
            <div className='management-body-item'>
                <button className='management-body-item-button' onClick={applyBtn}>반영</button>
                <button className='management-body-item-delete-button' onClick={removeDistrict}>삭제</button>
            </div>
        </div>
    </>)
}