import React, { useState, useEffect } from 'react';
import axios from "axios";
import { putValue } from '../../../util/deviceValues';
import { putValueTuya, putDeviceValue } from '../../../util/devicesTuya';

export default function DeviceCardInput(props) {
    const [value, setValue] = useState(props.value.value === 'true' ? true : props.value.value === 'false' ? false : props.value.value);
    const [writable, setWritable] = useState(props.value.writable === 'true');

    async function changeValue(e) {
        if (typeof (value) === 'boolean') {
            setValue(!value);
            console.log(props.device.id, props.device.partner_name, props.value.id, !value)
            if (props.device.partner_name === "tuya") {
                // putValueTuya(props.device.id, !value, props.value.id, props.value.name, props.currentUser.id);
                await putDeviceValue({
                    deviceId: props.device.id,
                    valueId: props.value.id,
                    newValue: !value,
                    valueSymbolicId: props.value.symbolic_id,
                })
            } else {
                const res = await putValue(props.value.device_id, props.value.id, !value);
                if (res === undefined) {
                    alert('제어에 실패하였습니다.');
                    return;
                }
            }
        } else {
            setValue(!value);
        }
    }

    return (<>
        <div className='device-card-wrapper-vertical'>
            <div className='device-card-data-wrapper'>
                <div className='device-card-data'>{props.value.name}</div>
            </div>
            <div className='device-card-data-wrapper'>
                <div className='device-card-data'>
                    {
                        typeof (value) === 'boolean' && // bool
                            writable === true ? <input checked={value} onChange={changeValue} role="switch" type="checkbox" className='toggle-switch' /> :
                            value === true ? props.value.unit?.split('/')[0] : props.value.unit?.split('/')[1]

                    }
                    {
                        (props.value.value !== 'true' && props.value.value !== 'false') &&
                        `${props.value.value}${props.value.unit}`
                    }
                </div>
            </div>
        </div>
    </>)
}