const { getLogin } = require("./login");

export async function connectWS(currentSite, currentDistrict) {
    try {
        const token = (await getLogin()).token;
        if (token === undefined) {
            return undefined;
        }
        if (currentDistrict === undefined || currentSite === undefined || token === undefined) {
            return undefined;
        }
        return new WebSocket(`${process.env.REACT_APP_WS_URL}?token=${token}&site_id=${currentSite.id}&district_id=${currentDistrict.id}`);
    } catch (e) {
        console.error(e);
        return undefined;
    }
}
