import axios from "axios";
import { putValue } from "../../../util/deviceValues";
import { putValueTuya, putDeviceValue } from "../../../util/devicesTuya";

async function delay(ms) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

export default function TV({ props }) {

    async function powerButton(isOn) {
        const newValue = isOn ? "true" : "false";

        try {
            console.log('전원 버튼 클릭');
            if (props.device.partner_name === "tuya") {
                const powerValue = props.device.values.find((value) => value.name === '전원');
                if (!isOn) {
                    // await putValueTuya(props.device.id, newValue, powerValue.id, powerValue.name, props.currentUser.id);
                    await putDeviceValue({
                        deviceId: props.device.id,
                        valueId: powerValue.id,
                        newValue: newValue,
                        valueSymbolicId: powerValue.symbolic_id,
                    });
                }

                // await putValueTuya(props.device.id, newValue, powerValue.id, powerValue.name, props.currentUser.id);
                await putDeviceValue({
                    deviceId: props.device.id,
                    valueId: powerValue.id,
                    newValue: newValue,
                    valueSymbolicId: powerValue.symbolic_id,
                });
            } else {
                const powerValueID = props.device.values.find((value) => value.name === '전원').id;

                if (!isOn) {
                    putValue(props.device.id, powerValueID, newValue);
                    await delay(500);
                }
                const res = await putValue(props.device.id, powerValueID, newValue);

                if (res === undefined) {
                    alert('전원 제어 실패');
                }
            }
        } catch (e) {
            console.error(e)
            alert('전원 제어 실패');
        }

    }

    const isOn = props.device?.values?.find((value) => value.name === '전원')?.value === 'true';

    return (<>
        <div className='device-card'>
            <div className='device-card-wrapper-vertical'>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>{props.device.name}</div>
                    <div style={{ textAlign: "center" }}>전원 {isOn ? "켜져있음" : "꺼져있음"}</div>
                </div>
                <div className="flex">
                    <div className="sidebar-div" onClick={() => powerButton(true)} >
                        <img src="/icon/power_settings_new_FILL0_wght400_GRAD0_opsz48.svg" />
                        ON
                    </div>
                    <div className="sidebar-div" onClick={() => powerButton(false)} >
                        <img src="/icon/power_settings_new_FILL0_wght400_GRAD0_opsz48.svg" />
                        OFF
                    </div>
                </div>
            </div>
        </div>
    </>)
}