import axios from 'axios';

export async function getDistricts(query) {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/districts`, {
            params: query,
            withCredentials: true,
        });

        return res.data.sort((a, b) => {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            else return 0;
        });
    } catch (e) {
        // console.error(e.response.data);
        return undefined;
    }
}

export async function postDistrict(data) {
    try {
        const body = {};
        for (const key in data) {
            if (data[key])
                body[key] = data[key];
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/districts`, body, {
            withCredentials: true,
        });

        return '공간 추가 성공';
    } catch (e) {
        console.error(e.response.data);
        return e.response.data ? e.response.data : '공간 추가 실패';
    }
}

export async function putDistrict(id, data) {
    try {
        const body = {};
        for (const key in data) {
            if (data[key])
                body[key] = data[key];
        }

        await axios.put(`${process.env.REACT_APP_API_URL}/districts/${id}`, body, {
            withCredentials: true,
        });

        return '공간 변경 성공';
    } catch (e) {
        console.error(e.response.data);
        return e.response.data ? e.response.data : '공간 변경 실패';
    }
}

export async function deleteDistrict(id) {
    try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/districts/${id}`, {
            withCredentials: true,
        });

        return '공간 삭제 성공';
    } catch (e) {
        console.error(e);
        return '공간 삭제 실패';
    }
}