import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { getDevices } from '../../util/devices';
import { getSites } from '../../util/sites';
import { getDistricts } from '../../util/districts';

import DeviceManagementItem from './DeviceManagementItem';
import CameraItem from './CameraItem';
import CameraAddItem from './CameraAddItem';
import DeviceAddModal from './DeviceAddModal';

export default function DeviceManagement({ props }) {
    const [devices, setDevices] = useState(undefined);
    const [sites, setSites] = useState(undefined);
    const [districts, setDistricts] = useState(undefined);
    const [updateFlag, setUpdateFlag] = useState(true);
    const [newDevice, setNewDevice] = useState(undefined);
    const [concents, setConcents] = useState([]);

    const [refreshFlag, setRefreshFlag] = useState(false);
    async function refresh() {
        try {
            setDevices(await getDevices({
                district_id: props.currentDistrict.id,
            }));
            setNewDevice(await getDevices({
                district_id: 'undefined',
            }));
        } catch { }
    }

    useEffect(() => {
        if (refreshFlag === true) {
            setRefreshFlag(false);
            refresh();
        }
    }, [refreshFlag]);

    async function update() {
        try {
            console.log(props);
            if (props.currentSite === undefined) return;
            const devices = await getDevices({
                district_id: props.currentDistrict.id,
                // site_id: props.currentSite.id,
            })
            setDevices(devices);
            setSites(await getSites());
            setDistricts(await getDistricts());
            setNewDevice(await getDevices({
                district_id: 'undefined',
            }));

            const concents = devices.filter((device) => device.type === '콘센트');
            setConcents(concents);
        } catch { }
    }

    function settingTuyaUser() {
        const tuyaUserId = document.getElementById("tuyaUserId").value.trim();

        if (tuyaUserId !== "") {
            axios.post(`${process.env.REACT_APP_TUYA_URL}/device/${tuyaUserId}`, {}, {
                headers: {
                    "authorization": process.env.REACT_APP_TUYA_AUTH
                }
            }).then(() => {
                refresh().then();
            }).catch((e) => {
                console.error("settingTuyaUser ERROR : ", e)
            });
        }
    }

    function clickRefresh() {
        refresh().then();
    }

    useEffect(() => {
        if (updateFlag === true) {
            setUpdateFlag(false);
        }
        update();
    }, [updateFlag, props.currentSite, props.currentDistrict]);

    return (<>
        {
            props.currentSite &&
            <div className='management-wrap'>
                <DeviceAddModal props={{ ...props, setUpdateFlag }} />
                <div className='management-wrap-row'>
                    <div className='management'>
                        Tuya 기기 등록
                        <input id={'tuyaUserId'} placeholder={'Tuya 사용자 ID'} />
                        <button type={"button"} onClick={settingTuyaUser}>등록</button>
                        <button type={"button"} onClick={clickRefresh}>새로고침</button>
                    </div>
                    <div className='management'>
                        등록된 기기 관리
                        <div className='management-header'>
                            <div className='management-header-item'>숨기기</div>
                            <div className='management-header-item'>이름</div>
                            <div className='management-header-item'>종류</div>
                            <div className='management-header-item'>타입</div>
                            <div className='management-header-item'>파트너</div>
                            <div className='management-header-item'>콘센트</div>
                            <div className='management-header-item'>지점</div>
                            <div className='management-header-item'>공간</div>
                            <div className='management-header-item'>적용</div>
                        </div>
                        {sites && districts && devices && devices.sort((a, b) => { if (a.name < b.name) return -1; if (a.name > b.name) return 1; return 0; }).map((device) => {
                            if (device.district_id !== undefined && device.type !== 'NVR' && device.type !== 'channel')
                                return <DeviceManagementItem key={device.id} device={device} sites={sites} districts={districts} setUpdateFlag={setUpdateFlag} concents={concents} />
                        })}
                    </div>
                    <div className='management'>
                        미등록 기기 관리
                        <div className='management-header'>
                            <div className='management-header-item'>숨기기</div>
                            <div className='management-header-item'>이름</div>
                            <div className='management-header-item'>종류</div>
                            <div className='management-header-item'>타입</div>
                            <div className='management-header-item'>파트너</div>
                            <div className='management-header-item'>콘센트</div>
                            <div className='management-header-item'>지점</div>
                            <div className='management-header-item'>공간</div>
                            <div className='management-header-item'>적용</div>
                        </div>
                        {newDevice && newDevice.sort((a, b) => { if (a.name < b.name) return -1; if (a.name > b.name) return 1; return 0; }).map((device, index) => {
                            return <DeviceManagementItem key={device.id} device={device} sites={sites} districts={districts} setUpdateFlag={setUpdateFlag} />
                        })}
                    </div>
                    <div className='management'>
                        카메라 관리
                        <div className='management-header'>
                            <div className='management-header-item'>이름</div>
                            <div className='management-header-item'>녹화기</div>
                            <div className='management-header-item'>URL</div>
                            <div className='management-header-item'>지점</div>
                            <div className='management-header-item'>공간</div>
                            <div className='management-header-item'>적용</div>
                        </div>
                        {sites && districts && devices && devices.map((device, index) => {
                            if (device.type === 'NVR' || device.type === 'channel')
                                return <CameraItem key={device.id} device={device} sites={sites} districts={districts} setUpdateFlag={setUpdateFlag} />
                        })}
                        {
                            sites && districts && devices &&
                            <CameraAddItem devices={devices} sites={sites} districts={districts} setUpdateFlag={setUpdateFlag} />
                        }
                    </div>
                </div>
            </div>
        }

    </>);
}
