import { useEffect, useState } from "react";
// import { putValue } from "../../../util/deviceValues";
import { putDeviceValue } from "../../../util/devicesTuya";

export default function Door({ props }) {

    const [sensor, setSensor] = useState(undefined);
    const [control, setControl] = useState(undefined);

    async function unlock() {
        try {
            console.log(control)

            // TODO 열기 닫기를 분리해야 함
            let res = await putDeviceValue({
                deviceId: control.device_id,
                valueId: control.id,
                newValue: true,
                valueSymbolicId: control.symbolic_id,
            });

            res = await putDeviceValue({
                deviceId: control.device_id,
                valueId: control.id,
                newValue: false,
                valueSymbolicId: control.symbolic_id,
            })

            if (res === undefined) {
                alert('문 해제 실패');
                return;
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        props.device.values.forEach((value) => {
            if (value.name === '문열림 상태') {
                const sensorValueID = value.symbolic_id;
                props.devices.forEach((d) => {
                    d.values.forEach((v) => {
                        if (v.id === sensorValueID) {
                            setSensor(v);
                        }
                    });
                });

            } else if (value.name === '문열림 제어') {
                const controlValueID = value.symbolic_id;
                props.devices.forEach((d) => {
                    d.values.forEach((v) => {
                        if (v.id === controlValueID) {
                            setControl(v);
                        }
                    });
                });
            }
        });
    }, [props.devices]);

    return (<>
        <div className='device-card'>
            {
                sensor && <img src={sensor.value === 'false' ? "/icon/door_front_FILL0_wght400_GRAD0_opsz48.svg" : "/icon/door_open_FILL0_wght400_GRAD0_opsz48.svg"} />
            }
            <div className='device-card-wrapper-vertical'>
                {props.device.name}
                <div className="sidebar-div" onClick={unlock}>
                    <img src="/icon/key_FILL0_wght400_GRAD0_opsz48.svg" />
                </div>
            </div>
        </div>
    </>)
}