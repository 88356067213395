import React, { useState, useEffect } from 'react';

export default function PermissionManagementTableForm({ props }) {

    const [newScope, setNewScope] = useState('');
    const [newRW, setNewRW] = useState('');
    const [temporaryTargetUser, setTemporaryTargetUser] = useState({});

    const [scope, setScope] = useState(props[`${props.resource}s`]);

    function changeScope(e) {
        setNewScope(e.target.value);
        setNewRW('');
        setTemporaryTargetUser(Object.assign({}, props.targetUser));
    }
    function changeRW(e) {
        if (newScope === '') {
            return alert('접근 범위를 선택해주세요.');
        }
        setNewRW(e.target.value);
        temporaryTargetUser.permission[props.resource][newScope] = {};
        switch (e.target.value) {
            case 'r':
                temporaryTargetUser.permission[props.resource][newScope]['read'] = true;
                temporaryTargetUser.permission[props.resource][newScope]['write'] = false;
                break;
            case 'w':
                temporaryTargetUser.permission[props.resource][newScope]['read'] = false;
                temporaryTargetUser.permission[props.resource][newScope]['write'] = true;
                break;
            case 'rw':
                temporaryTargetUser.permission[props.resource][newScope]['read'] = true;
                temporaryTargetUser.permission[props.resource][newScope]['write'] = true;
                break;
            case '':
                temporaryTargetUser.permission[props.resource][newScope]['read'] = false;
                temporaryTargetUser.permission[props.resource][newScope]['write'] = false;
                break;
            default:
                break;
        }
        props.setTargetUser(temporaryTargetUser);
    }

    return (<>
        <div className='management-body'>
            <div className='management-body-item'>
                <select onChange={changeScope} value={newScope} >
                    <option value={''}>접근 범위 선택</option>
                    {
                        !props.targetUser.permission[props.resource]['*'] &&  <option value={'*'}>*</option>
                    }
                    {
                        scope && scope.map((s) => {
                            if (s.deactivated) return; // 비활성 계정은 목록에서 보이지 않음
                            if (props.targetUser.permission[props.resource][s.id]) return; // 자기 자신은 목록에서 보이지 않음
                            return <option key={s.id} value={s.id}>{s.name ? s.name : s.id}</option>
                        })
                    }
                </select>
            </div>
            <div className='management-body-item'>
                <select onChange={changeRW} value={newRW} >
                    <option value={'r'}>읽기</option>
                    <option value={'w'}>쓰기</option>
                    <option value={'rw'}>읽쓰기</option>
                    <option value={''}>없음</option>
                </select>
            </div>
        </div>
    </>)
}