import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../component/Management.css'

import SessionCheck from './SessionCheck';
import NavBar from '../component/NavBar/NavBar';
import Sidebar from '../component/Sidebar';
import UserManagement from '../component/User/UserManagement';
import SiteManagement from '../component/Site/SiteManagement';
import DistrictManagement from '../component/District/DistrictManagement';
import Monitoring from './Monitoring';
import PermissionManagement from '../component/Permission/PermissionManagement';
import DeviceManagement from '../component/DeviceManagement/DeviceManagement';
// import { register } from '../util/firebase';

import { getLogin } from '../util/login';

export default function Dashboard() {
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState(undefined); // 현재 로그인한 유저 정보
    const [currentView, setCurrentView] = useState('DeviceManagement');
    const [currentSite, setCurrentSite] = useState(undefined);
    const [currentDistrict, setCurrentDistrict] = useState(undefined);
    const [districts, setDistricts] = useState([]);
    const [sites, setSites] = useState([]);
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [token, setToken] = useState(undefined);
    const [ws, setWS] = useState(undefined);
    const [devices, setDevices] = useState([]);
    const [websocket, setWebsocket] = useState(undefined);

    const props = {
        currentView: currentView,
        setCurrentView: setCurrentView,
        currentSite: currentSite,
        setCurrentSite: setCurrentSite,
        currentDistrict: currentDistrict,
        setCurrentDistrict: setCurrentDistrict,
        districts: districts,
        setDistricts: setDistricts,
        sites: sites,
        setSites: setSites,
        groups: groups,
        users: users,
        setUsers: setUsers,
        setGroups: setGroups,
        sidebar: sidebar,
        setSidebar: setSidebar,
        token: token,
        currentUser: currentUser,
        ws: ws,
        setWS: setWS,
        devices: devices,
        setDevices: setDevices,
        websocket: websocket,
        setWebsocket: setWebsocket,
    }

    async function checkNotification() {
        try {
            const permission = await Notification.requestPermission();
            if (permission !== 'granted') {
                Notification.requestPermission();
                alert('알람을 받으려면 권한 승인이 필요합니다');
                return;
            }
            // register();
        } catch (err) {
            console.log(err);
        }
    }

    async function updateUserInfo() {
        const user = await getLogin();
        if (user === undefined) {
            navigate('/login');
        } else {
            setCurrentUser(user);
        }
    }

    useEffect(() => {
        // checkNotification();
        updateUserInfo();
    }, [currentView]);

    return (<div>
        <SessionCheck setToken={setToken} />
        <NavBar props={props} />

        <div style={
            {
                display: 'flex',
                flexDirection: 'row',
            }
        }>
            {sidebar && <Sidebar props={props} />}
            {currentView == 'Monitoring' && <Monitoring props={props} />}
            {currentView == 'UserManagement' && <UserManagement props={props} />}
            {currentView == 'SiteManagement' && <SiteManagement props={props} />}
            {currentView == 'DistrictManagement' && <DistrictManagement props={props} />}
            {currentView == 'PermissionManagement' && <PermissionManagement props={props} />}
            {currentView == 'DeviceManagement' && <DeviceManagement props={props} />}
        </div>

    </div>)
}
