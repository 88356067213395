import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from "react"

import { getDevices } from '../util/devices';
import { connectWS } from '../util/websocket';

import Common from "../component/Device/type/Common";
import AC from '../component/Device/type/AC';
import Channel from "../component/Device/type/Channel";
import VirtualCommon from "../component/Device/type/VirtualCommon";
import Door from "../component/Device/type/Door";
import PC from "../component/Device/type/PC";
import TV from "../component/Device/type/TV";
import Wallpad from '../component/Device/type/Wallpad';

export default function Monitoring({ props }) {
    const navigate = useNavigate();
    const [devices, setDevices] = useState(undefined);
    let devicesTemp = [];
    const wsList = useRef([]);
    const [updateFlag, setUpdateFlag] = useState(true);

    async function update() {
        await updateDevice();
        await openWebSocket();
    }
    async function openWebSocket() {
        try {
            const websocket = await connectWS(props.currentSite, props.currentDistrict);
            if (websocket === undefined) {
                console.log(`웹소켓 연결 실패`);
                navigate('/login');
                return false;
            }
            wsList.current.push(websocket);
            websocket.onopen = () => {
                console.log(`웹소켓 연결됨: ${props.currentDistrict.name}`);
            }
            websocket.onclose = () => {
                console.log(`웹소켓 연결 종료됨`);
                setUpdateFlag(true);
            }
            websocket.onerror = (err) => {
                console.log(`웹소켓 에러 발생`);
                console.log(err);
                setUpdateFlag(true);
            }
            websocket.onmessage = (event) => {
                try {
                    console.log(`웹소켓 메시지 수신`);
                    const data = JSON.parse(event.data);
                    if (data.device.id !== undefined) {
                        const device = data.device;
                        const index = devicesTemp.findIndex((d) => d.id === device.id);
                        if (index === -1) {
                            console.log(`재 동기화 필요`);
                            setUpdateFlag(true);
                        } else {
                            devicesTemp[index] = device;
                            setDevices([...devicesTemp]);
                            console.log(`기기 정보 업데이트`);
                        }
                    }
                } catch (e) {
                    console.log(`웹소켓 메시지 파싱 실패:${e}`);
                    updateDevice();
                    console.log(devices);
                }
            }

            return true;
        } catch {
            console.log(`웹소켓 연결 실패`);
        }
    }
    async function updateDevice() {
        try {
            if (props.currentDistrict === undefined) return;
            devicesTemp = await getDevices({
                'district_id': props.currentDistrict.id,
            });
            setDevices(devicesTemp);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        try {
            if (updateFlag === true && props.currentDistrict !== undefined) {
                setUpdateFlag(false);
                update();
            }
            return () => {
                wsList.current.forEach((ws) => {
                    if (ws && ws.readyState === WebSocket.OPEN) {
                        ws.close();
                    }
                });
            }
        } catch { }
    }, [updateFlag, props.currentDistrict]);


    return (<>
        <div className="monitoring-wrapper">
            {
                devices && devices.sort((a, b) => { if (a.type < b.type) return -1; if (a.type > b.type) return 1; return 0; }).map((device) => {
                    switch (device.type) {
                        case '에어컨': {
                            return <AC key={device.id} props={{ ...props, device, devices }} />
                        }
                        case 'channel': {
                            return <Channel key={device.id} device={device} />
                        }
                        case 'NVR': {
                            return;
                        }
                        case 'virtual': {
                            return <VirtualCommon key={device.id} props={{ ...props, device, devices }} />
                        }
                        case '문': {
                            return <Door key={device.id} props={{ ...props, device, devices }} />
                        }
                        case 'PC': {
                            return <PC key={device.id} props={{ ...props, device, devices }} />
                        }
                        case 'TV': {
                            return <TV key={device.id} props={{ ...props, device, devices }} />
                        }
                        case '월패드': {
                            return <Wallpad key={device.id} props={{ ...props, device, devices }} />
                        }
                        default: {
                            return <Common key={device.id} props={{ ...props, device }} />
                        }
                    }
                })
            }
        </div>
    </>)
}