import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/login`;

export default function SessionCheck(props) {
    const navigate = useNavigate();

    async function getLogin() {
        try {
            const response = await axios.get(API_URL, { withCredentials: true });

            if (response.status === 200) {
                props.setToken(response.data.token);
                navigate('/dashboard');
            } else {
                navigate('/login');
            }
        } catch (e) {
            navigate('/login');
        }
    }

    useEffect(() => { // 세션 체크
        getLogin();
    }, []);

    return (<>
    </>)
}