import React, { useState, useEffect } from 'react';
import { postDistrict } from '../../util/districts';

export default function AddDistrict({props}) {
    const [name, setName] = useState('');

    async function addDistrict() {
        try {
            if (name === '') {
                alert('이름을 입력해주세요.');
                return;
            }
            const message = await postDistrict({
                name: name,
                site_id: props.currentSite.id,
            });
            alert(message);
        } catch (e) {
        }
    }

    return (<>
        <div className='management-body'>
            <div className='management-body-item'>
                <input placeholder='공간이름 입력' type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
            </div>
            <div className='management-body-item'>{props.currentSite.name}</div>
            <div className='management-body-item'>
                <button className='management-body-item-button' onClick={addDistrict}>생성</button>
                <button className='management-body-item-delete-button' onClick={() => { props.setAddNew(false)}}>취소</button>
            </div>
        </div>
    </>)
}