import { useEffect, useState } from "react";

export default function Wallpad({ props }) {
    const [control, setControl] = useState(undefined);
    const [cmsCode, setCmsCode] = useState(undefined);


    useEffect(() => {
        props.device.values.forEach((value) => {
            if (value.name === '문열림 제어') {
                const controlValueID = value.symbolic_id;
                props.devices.forEach((d) => {
                    d.values.forEach((v) => {
                        if (v.id === controlValueID) {
                            setControl(v);
                        }
                    });
                });
            } else if (value.name === 'CMSID') {
                setCmsCode(value.value);
            }
        });
    }, [props.devices]);
    return (<>
        <div className='device-card'>
            <div className='device-card-wrapper-vertical'>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>이름</div>
                </div>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>{props.device.name}</div>
                </div>
            </div>
            <div className='device-card-wrapper-vertical'>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>월패드 ID</div>
                </div>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>{props.device.id}</div>
                </div>
            </div>
            <div className='device-card-wrapper-vertical'>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>제어기</div>
                </div>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>{control ? control.name : '에러'}</div>
                </div>
            </div>
            <div className='device-card-wrapper-vertical'>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>CMS 연동 코드</div>
                </div>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>{cmsCode ? cmsCode : '에러'}</div>
                </div>
            </div>
        </div>
    </>);
}