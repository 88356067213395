import axios from 'axios';
import { tuyaApi } from './axios-global-instance';

export async function login() {
    let res;
    try {
        res = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
            withCredentials: true,
        });
        return res;
    } catch (e) {
        return res;
    }
}

export async function getLogin() {
    let res;
    try {
        res = await axios.get(`${process.env.REACT_APP_API_URL}/login`, {
            withCredentials: true,
        });
        try {
            res.data.permission = JSON.parse(res.data.permission);

            tuyaApi.defaults.headers['user-id'] = encodeURI(res.data.id);
        } catch { }
        return res.data;
    } catch (e) {
        console.log(`세션 확인 실패`);
        return undefined;
    }
}

export async function logout() {
    try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/login`, {
            withCredentials: true,
        });
    } catch (e) {
        console.error(e.response.data);
    }
}