import React, { useState, useEffect } from 'react';
import PermissionManagementTableItem from './PermissionManagementTableItem';
import PermissionManagementTableForm from './PermissionManagementTableForm';

export default function PermissionManagementTable({ props }) {

    return (<>
        <div className='management-header'>
            <div className='management-header-item'>접근 범위</div>
            <div className='management-header-item'>권한</div>
            <div className='management-header-item'>삭제</div>
        </div>
        {
            props.targetUser && props.targetUser.permission &&
            props.targetUser.permission[props.resource] && Object.keys(props.targetUser.permission[props.resource]).map((key) => {
                console.log(key);
                return <PermissionManagementTableItem key={key} props={{ ...props, id: key }} />
            })
        }
        {   
            props.targetUser && props.targetUser.permission &&
            <PermissionManagementTableForm key={'newOne'} props={{ ...props }} />
        }
    </>)
}