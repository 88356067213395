import React, { useState, useEffect } from 'react';
import { getUsers , putUser } from '../../util/users';
import { getSites } from '../../util/sites';
import { getDistricts } from '../../util/districts';
import  { getDevices } from '../../util/devices';
import PermissionManagementTable from './PermissionManagementTable';

export default function PermissionManagement({ props }) {
    const [targetUser, setTargetUser] = useState(undefined);

    async function applyPermission() {
        if (targetUser === undefined) return alert('변경할 대상을 선택해주세요.');
        const message = await putUser(targetUser.id, {
            permission: JSON.stringify(targetUser.permission),
        });

        alert(message);
        update();
    }

    async function changeUser(e) {
        const user_id = e.target.value;
        const filteredUser = props.users ? props.users.filter(user => user.id === user_id)[0] : undefined;
        if (filteredUser !== undefined) {
            setTargetUser(filteredUser);
        }
    }

    async function update() {
        const users = await getUsers();
        props.setUsers(users);
        const sites = await getSites();
        props.setSites(sites);
        const districts = await getDistricts();
        props.setDistricts(districts);
        const devices = await getDevices();
        props.setDevices(devices);
    }

    useEffect(() => {
        update();
    }, []);


    return (<>
        <div className='management-wrap'>
            <div>
                <select onChange={changeUser} className='management-user-select'>
                    {
                        <option key={'default_value'} value={undefined}>권한 변경 대상자 선택</option>
                    }
                    {
                        props.users && props.users.map((user) => {
                            if (user.deactivated) return; // 비활성 계정은 목록에서 보이지 않음
                            return <option key={user.id} value={user.id}>{user.name ? user.name : user.id}</option>
                        })
                    }
                </select>
            </div>
            <div className='management-wrap-row'>
                <div className='management'>
                    {
                        targetUser && targetUser.permission && targetUser.permission.user &&
                        <div>계정 권한 관리</div>
                    }
                    {
                        targetUser && targetUser.permission && targetUser.permission.user &&
                        <PermissionManagementTable key={targetUser.id} props={{ ...props, resource: 'user', targetUser, setTargetUser }} />
                    }
                </div>
                <div className='management'>
                    {
                        targetUser && targetUser.permission && targetUser.permission.site &&
                        <div>매장 권한 관리</div>
                    }
                    {
                        targetUser && targetUser.permission && targetUser.permission.site &&
                        <PermissionManagementTable key={targetUser.id} props={{ ...props, resource: 'site', targetUser, setTargetUser }} />
                    }
                </div>
                <div className='management'>
                    {
                        targetUser && targetUser.permission && targetUser.permission.district &&
                        <div>공간 권한 관리</div>
                    }
                    {
                        targetUser && targetUser.permission && targetUser.permission.district &&
                        <PermissionManagementTable key={targetUser.id} props={{ ...props, resource: 'district', targetUser, setTargetUser }} />
                    }
                </div>
                <div className='management'>
                    {
                        targetUser && targetUser.permission && targetUser.permission.device &&
                        <div>기기 권한 관리</div>
                    }
                    {
                        targetUser && targetUser.permission && targetUser.permission.device &&
                        <PermissionManagementTable key={targetUser.id} props={{ ...props, resource: 'device', targetUser, setTargetUser }} />
                    }
                </div>
            </div>
            <div className='management-add-button' onClick={applyPermission}>
                <p>적용하기</p>
            </div>
        </div>
    </>)
}