import axios from 'axios';

export async function getDevice(id) {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/devices/${id}`, {
            withCredentials: true,
        });
        return res;
    } catch (e) {
        // console.error(e.response.data);
        return undefined;
    }
}
export async function getDevices(query) {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/devices`, {
            params: query,
            withCredentials: true,
        });

        return res.data;
    } catch (e) {
        // console.error(e.response.data);
        return [];
    }
}

export async function putDevice(device) {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/devices/${device.id}`, device, {
            withCredentials: true,
        });

        return res;
    } catch (e) {
        // console.error(e.response.data);
        return undefined;
    }
}

export async function deleteDevice(id) {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/devices/${id}`, {
            withCredentials: true,
        });

        return res;
    } catch (e) {
        // console.error(e.response.data);
        return undefined;
    }
}

export async function postDevice(device) {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/devices`, device, {
            withCredentials: true,
        });

        return res;
    } catch (e) {
        // console.error(e.response.data);
        return undefined;
    }
}