import React, { useEffect, useState } from 'react';
import DeviceCardInput from './DeviceCardInput';
import './Device.css';

export default function Common({ props }) {
    const [hide, setHide] = useState(false);
    useEffect(() => {
        props.device.values.forEach((value) => {
            if (value.name === 'hide') {
                setHide(value.value === 'true' ? true : false);
            }
        });

    }, []);
    useEffect(() => {
    }, [hide]);
    return (<>
        {
            hide !== true &&
            <div className='device-card'>
                <div className='device-card-wrapper-vertical'>
                    <div className='device-card-data-wrapper'>
                        <div className='device-card-data'>{props.device.name}</div>
                    </div>
                    <div className='device-card-data-wrapper'>
                        <div className='device-card-data'>{props.device.type}</div>
                    </div>
                </div>
                {
                    props.device && props.device.values && props.device.values.sort((a, b) => a.name > b.name ? 1 : -1).map((value) => {
                        if (value.name === 'hide' || value.name === 'agt' || value.name === 'me') {
                            return;
                        }
                        return <DeviceCardInput key={`${value.id}${value.value}`} value={value} device={props.device} currentUser={props.currentUser} />
                    })
                }
            </div>
        }
    </>)
}