import React, { useState, useEffect } from 'react';
import { putUser, deleteUser } from '../../util/users';


export default function UserManagementItem(props) {
    const [name, setName] = useState(props.user.name);
    const [password, setPassword] = useState('');

    async function applyBtn() {
        try {
            const user = {};

            if (password.length >= 1 && password.length < 8) {
                alert('비밀번호는 8자 이상이어야 합니다.');
                setPassword('');
                return;
            }

            if (password.length >= 8)
                user['password'] = password;
            if (name !== props.user.name)
                user['name'] = name;

            if (user.length !== 0) {
                const res = await putUser(props.user.id, user);
               
                alert('반영 성공');
                props.getUser();
            } else {
                alert('변경할 내용이 없습니다.');
            }
        } catch (error) {
            alert('변경 실패');
        }
    }

    async function deleteBtn() {
        try {
            const result = await deleteUser(props.user.id);
            alert(result);
            props.getUser();
        } catch (error) {
            alert(`삭제 실패: ${error.response.data.message}`);
            props.getUser();
        }
    }

    return (<>
        <div className='management-body'>
            <div className='management-body-item'>{props.user.id}</div>
            <div className='management-body-item'>
                <input type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
            </div>
            <div className='management-body-item'>
                <input placeholder='비밀번호 입력' type='password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
            </div>
            <div className='management-body-item'>
                <button className='management-body-item-button' onClick={applyBtn}>반영</button>
                <button className='management-body-item-delete-button' onClick={deleteBtn}>삭제</button>
            </div>
        </div>
    </>)
}