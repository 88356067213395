import React, { useState, useEffect } from 'react';
import { putDevice, deleteDevice, controlDevice } from '../../util/devices';
import { putValue, postValue } from '../../util/deviceValues';

export default function CameraItem(props) {
    const oldName = props.device.name;
    const [name, setName] = useState(oldName);

    const oldSiteID = props.device.site_id;
    const [siteID, setSiteID] = useState(oldSiteID);

    const oldDistrictID = props.device.district_id;
    const [districtID, setDistrictID] = useState(oldDistrictID);

    const oldURL = (props.device.type === 'NVR') ?
        props.device.values.filter(value => value.name === 'ddns')[0] ? props.device.values.filter(value => value.name === 'ddns')[0].value : undefined
        : props.device.values.filter(value => value.name === 'url')[0] ? props.device.values.filter(value => value.name === 'url')[0].value : undefined;
    const [url, setURL] = useState(oldURL);

    const [districts, setDistricts] = useState([]); // 선택 가능한 distrcit 목록

    function districtChange(e) {
        setDistrictID(e.target.value);
    }
    function siteChange(e) {
        setSiteID(e.target.value);
    }
    async function applyBtn() {
        if (name === oldName && districtID === oldDistrictID && url === oldURL) {
            alert('변경된 사항이 없습니다.');
        } else {
            try {
                if (name !== oldName) {
                    const result = await putDevice({
                        id: props.device.id,
                        name: name,
                        district_id: districtID,
                    });
                    if (result === undefined) {
                        alert('변경에 실패하였습니다.');
                        return;
                    }
                }

                if (oldURL !== url) { // url 변경시
                    if (props.device.type === 'NVR') {
                        const valueID = props.device.values.filter(value => value.name === 'endpoint')[0].id;
                        const result = await putValue(props.device.id, valueID, url);
                        if (result === undefined) {
                            alert('변경에 실패하였습니다.');
                            return;
                        }
                    } else {
                        if (props.device.values.filter(value => value.name === 'url')[0]) {
                            const valueID = props.device.values.filter(value => value.name === 'url')[0].id;
                            const result = await putValue(props.device.id, valueID, url);
                            if (result === undefined) {
                                alert('변경에 실패하였습니다.');
                                return;
                            }
                        } else {
                            const result = await postValue(props.device.id, {
                                name: 'url',
                                value: url,
                                type: 'string',
                                writable: true,
                            });
                            if (result === undefined) {
                                alert('변경에 실패하였습니다.');
                                return;
                            }
                        }


                    }
                }
                alert('변경되었습니다.');
            } catch {
                alert('변경에 실패하였습니다.');
            }
            props.setUpdateFlag(true);

        }
    }
    async function deleteBtn() {
        try {
            const result = await deleteDevice(props.device.id);
            if (result === undefined) {
                alert('삭제에 실패하였습니다.');
                return;
            } else {
                alert('삭제되었습니다.');
            }
        } catch {
            alert('삭제에 실패하였습니다.');
        }
        props.setUpdateFlag(true);
    }

    useEffect(() => { // 최초 실행 로직
        if (districtID !== undefined) {
            setDistricts(props.districts.filter(district => district.site_id === siteID));
            setSiteID(props.districts.filter(district => district.id === districtID)[0].site_id);
        }
    }, []);

    useEffect(() => {
        if (siteID !== undefined) {
            setDistricts(props.districts.filter(district => district.site_id === siteID));
        }
    }, [siteID]);

    return (<>
        <div className='management-body'>
            <div className='management-body-item'>
                <input type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
            </div >
            <div className='management-body-item'>
                {
                    props.device.type === 'NVR' ?
                        '녹화기' :
                        '카메라'
                }
            </div>
            <div className='management-body-item'>
                <input type='text' value={url} onChange={(e) => { setURL(e.target.value) }} />
            </div>
            <div className='management-body-item'>
                <select onChange={siteChange} value={siteID}>
                    <option value={undefined}>선택</option>
                    {
                        props.sites && props.sites.map((site) => {
                            return <option key={site.id} value={site.id}>{site.name}</option>
                        })
                    }
                </select>
            </div>

            <div className='management-body-item'>
                {
                    <select onChange={districtChange} value={districtID}>
                        <option value={undefined}>선택</option>
                        {
                            districts && districts.map((district) => {
                                return <option key={district.id} value={district.id}>{district.name}</option>
                            })
                        }
                    </select>
                }
            </div>
            <div className='management-body-item'>
                <button className='management-body-item-button' onClick={applyBtn}>적용</button>
                <button className='management-body-item-delete-button' onClick={deleteBtn}>삭제</button>
            </div>
        </div>
    </>)
}