import React, { useState, useEffect } from 'react';
import { getSites } from '../../util/sites';

import './SiteSelect.css';


const url = `${process.env.REACT_APP_API_URL}/site`;

export default function SiteSelect({ props }) {
    async function update() {
        const sites = await getSites();
        if (sites !== undefined) {
            props.setSites(sites);
            const selectSite = sessionStorage.getItem("selectSite");

            const targetSite = selectSite !== null ? JSON.parse(selectSite) : sites[0];
            sessionStorage.setItem("selectSite", JSON.stringify(targetSite));
            props.setCurrentSite(targetSite);
        }
    }
    useEffect(() => {
        update();
    }, []);

    function changeSite(e) {
        try {
            const targetSite = props.sites.filter(site => site.id === e.target.value)[0];
            sessionStorage.setItem("selectSite", JSON.stringify(targetSite));
            props.setCurrentSite(targetSite);
        } catch { }
    }
    return (<>
        {
            props.sites && props.sites.length === 0 ? <></> :
                <select onChange={changeSite} className='site-select-box' defaultValue={props.currentSite?.id}>
                    {
                        props.sites && props.sites.map((site) => {
                            return <option key={site.id} value={site.id}>{site.name}</option>
                        }
                        )}
                </select>
        }
    </>)
}
