import React from "react";
import { Routes, Route, Link } from "react-router-dom";

import "./App.css";

import Login from "./pages/Login"; // 로그인 페이지
import Join from "./pages/Join"; // 회원가입 페이지
import Dashboard from "./pages/Dashboard"; // 대시보드 페이지 (메인)
import Root from "./pages/Root"; // 루트 도메인 -> 세션에 따라 다른 페이지로 리다렉
import Test from "./pages/Test";

function App() {
  return (<>
    <Routes>
      <Route path="/" element={<Root />}></Route>
      <Route path="/login" element={<Login />}></Route>
      {/* <Route path="/join" element={<Join />}></Route> */}
      <Route path="/dashboard" element={<Dashboard />}></Route>
      <Route path="/test" element={<Test />}></Route>
    </Routes>
  </>);
}

export default App;
