import { useState } from "react";
import { putValue } from "../../../util/deviceValues";
import axios from "axios";
import { putValueTuya, putDeviceValue } from "../../../util/devicesTuya";

export default function InputArrow(props) {
    const [value, setValue] = useState(Number(props.value.value));

    async function changeValue(e) {
        try {
            setValue(e.target.value);
            const res = await putValue(props.value.device_id, props.value.id, e.target.value);
            if (res === undefined) {
                alert('제어에 실패하였습니다.');
                return;
            }
        } catch {
            alert('제어에 실패하였습니다.');
        }
    }
    async function changeValueUp(e) {
        try {
            if (value + 1 > props.max) return;
            setValue(value + 1);
            if (props.device.partner_name === "tuya") {
                const tempValue = props.device.values.find((value) => value.name === '온도');

                // await putValueTuya(props.device.id, value + 1, tempValue.id, tempValue.name, props.currentUser.id);
                await putDeviceValue({
                    deviceId: props.device.id,
                    valueId: props.value.id,
                    newValue: value + 1,
                    valueSymbolicId: props.value.symbolic_id,
                });
            } else {
                const res = await putValue(props.value.device_id, props.value.id, value + 1);
                if (res === undefined) {
                    alert('제어에 실패하였습니다.');
                    return;
                }
            }
        } catch {
            alert('제어에 실패하였습니다.');
        }
    }
    async function changeValueDown(e) {
        try {
            if (value - 1 < props.min) return;
            setValue(value - 1);
            if (props.device.partner_name === "tuya") {
                const tempValue = props.device.values.find((value) => value.name === '온도');

                // await putValueTuya(props.device.id, value - 1, tempValue.id, tempValue.name, props.currentUser.id);
                await putDeviceValue({
                    deviceId: props.device.id,
                    valueId: props.value.id,
                    newValue: value - 1,
                    valueSymbolicId: props.value.symbolic_id,
                });
            } else {
                const res = await putValue(props.value.device_id, props.value.id, value - 1);
                if (res === undefined) {
                    alert('제어에 실패하였습니다.');
                    return;
                }
            }
        } catch {
            alert('제어에 실패하였습니다.');
        }
    }

    return (<>
        <div className='device-card-wrapper-vertical'>
            <div className='device-card-data-wrapper'>
                <div className='device-card-data-narrow'>
                    {props.label}
                </div>
                <div className='device-card-data-narrow'>
                    <input type="number" className='input-number' min={props.min ? props.min : 0} max={props.max ? props.max : 100} value={value} onChange={changeValue} />

                </div>
            </div>
        </div>
        <div className='device-card-wrapper-vertical'>
            <div className='device-card-data-wrapper'>
                <div className='device-card-data-narrow'>
                    <button className='btn-arrow-up' onClick={changeValueUp} />
                </div>
                <div className='device-card-data-narrow'>
                    <button className='btn-arrow-down' onClick={changeValueDown} />
                </div>
            </div>
        </div>
    </>)
}