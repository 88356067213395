import axios from "axios";
import { putValueTuya, putDeviceValue } from "../../../util/devicesTuya";

const { useState } = require("react");
const { putValue } = require("../../../util/deviceValues");

export default function InputSlide(props) {
    const [value, setValue] = useState(props.value.value === 'true' ? true : props.value.value === 'false' ? false : props.value.value);

    async function changeValue(e) {
        setValue(!value);
        if (props.device.partner_name === "tuya") {
            const powerValue = props.device.values.find((value) => value.name === '전원');

            // await putValueTuya(props.device.id, !value, powerValue.id, powerValue.name, props.currentUser.id);
            await putDeviceValue({
                deviceId: props.device.id,
                valueId: props.value.id,
                newValue: !value,
                valueSymbolicId: props.value.symbolic_id,
            })
        } else {
            const res = await putValue(props.value.device_id, props.value.id, !value);
            if (res === undefined) {
                alert('제어에 실패하였습니다.');
                return;
            }
        }
    }

    return (<>
        <input checked={value} onChange={changeValue} role="switch" type="checkbox" className='toggle-switch' />
    </>)
}