import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../LoginForm.css";
import {postUser} from '../../util/users';


export default function RegisterForm(props) {
    const navigate = useNavigate();

    const [username, setUsername] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [password2, setPassword2] = useState(undefined);
    const [error, setError] = useState(null);

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handlePassword2Change = (event) => {
        setPassword2(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password == password2) {
            alert(await postUser({
                id: username,
                password: password,
                name: username,
            }));         
            
        } else {
            setError(`비밀번호가 일치하지 않음`);
        }
    };

    return (<>
        <div className='join-wrapper'>
            <p className='login_p'>회원가입</p>
            <form onSubmit={handleSubmit}>
                <div>
                    <label className='id_label' htmlFor="username">아이디:</label>
                    <input
                        className='id_input'
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUsernameChange}
                    />
                </div>
                <div>
                    <label className='password_lable' htmlFor="password">비밀번호:</label>
                    <input
                        className='password_input'
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                <div>
                    <label className='password_lable' htmlFor="password2">비밀번호 확인:</label>
                    <input
                        className='password_input'
                        type="password"
                        id="password2"
                        value={password2}
                        onChange={handlePassword2Change}
                    />
                </div>
                <button className='login_btn' type="submit">회원가입</button>
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </form>
        </div>
    </>)
}