import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './NavBar.css';
import SiteSelect from '../Site/SiteSelect';
import DistrictButton from './DistrictButton';
// import { unregister } from '../../util/firebase';
import {logout} from '../../util/login';
import { getDistricts } from '../../util/districts';

export default function NavBar({ props }) {
    const navigate = useNavigate();
    const [districts, setDistricts] = useState([]);

    async function update() {
        try {
            const districts = await getDistricts({
                'site_id': props.currentSite.id,
            });

            if (districts !== undefined) {
                setDistricts(districts);
                props.setCurrentDistrict(districts[0]);
            } else {
                setDistricts([]);
                props.setCurrentDistrict(undefined);
            }
        } catch { }
    }

    function toggleSidebar(e) {
        e.preventDefault();
        props.setSidebar(!props.sidebar);
    }

    async function logoutBtn() {
        try {
            await logout();
            // await unregister(); //FCM 토큰 삭제
        } catch {}
        navigate('/');
    }

    useEffect(() => {
        update();
    }, [props.currentSite]);

    return (<>
        <div className='nav-wrapper'>
            <div className='nav-wrapper-side'>
                <button onClick={toggleSidebar} className='sidebar-toggle' />
                <SiteSelect props={props} />
            </div>
            <div className='nav-wrapper-main'>
                <div className='nav-menu'>
                    <div className='district-button-wrapper'>
                        {
                            districts && districts.map((district, index) => {
                                return (<DistrictButton key={index} props={{
                                    ...props,
                                    district,
                                }} />)
                            })
                        }
                    </div>
                </div>
                <button onClick={logoutBtn} className='nav-logout' />
            </div>
        </div>
    </>)
}
