import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import UserManagementItem from './UserManagementItem';
import JoinForm from './JoinForm';

import {getUsers} from '../../util/users';

export default function UserManagement({props}) {
    const [users, setUsers] = useState([]); // 계정 목록
    const [join, setJoin] = useState(false); // 계정 추가 창

    async function getUser() {
        try {
            const users = await getUsers();
            setUsers(users);
        } catch (error) {
        }
    }

    useEffect(() => {
        getUser();
    }, []);

    return (<>
        <div className='management-wrap'>
            <div className='management-add-button' onClick={() => { setJoin(!join) }}>
                <p>{!join ? '계정 추가' : '계정 관리'}</p>
            </div>
            {join && <JoinForm getUser={getUser} />}
            {!join && <div className='management'>
                <div className='management-header'>
                    <div className='management-header-item'>ID</div>
                    <div className='management-header-item'>이름</div>
                    <div className='management-header-item'>비밀번호 변경</div>
                    <div className='management-header-item'>적용</div>
                </div>
                {
                    users && users.map((user) => {
                        if (user.deactivated) return;
                        return <UserManagementItem key={user.id} user={user} getUser={getUser} />
                    })
                }
            </div>}
        </div>
    </>)
}