import React, { useState } from 'react';
import LoginForm from '../component/LoginForm';
import SessionCheck from './SessionCheck';
import './Login.css';

export default function Login() {
    return (<>
        <SessionCheck />
        <div className='logo_backgroud'>
            <img src="/logo.png" className="logo_img" alt="logo" />
        </div>
        <LoginForm />
        <p className='version'>{process.env.REACT_APP_NPM_PACKAGE_VERSION}</p>
    </>)
}