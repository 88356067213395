import React, { useState, useEffect } from 'react';
import DistrictManagementItem from './DistrictManagementItem';
import AddDistrict from './AddDistrict';
import { getDistricts } from '../../util/districts';

export default function DistrictManagement({ props }) {
    const [addNew, setAddNew] = useState(false); // 새 구획 추가 여부
    const [width, setWidth] = useState(0);


    async function update() {
        try {
            const message = await getDistricts();
            if (message !== undefined) {
                props.setDistricts(message);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        update();
    }, [props.currentSite]);

    return (<>
        {
            props.currentSite &&
            <div className='management-wrap'>
                <div className='management-add-button' onClick={() => { setAddNew(!addNew) }}>
                    <p>{!addNew ? '공간 추가' : '공간 관리'}</p>
                </div>
                <div className='management'>
                    <div className='management-header'>
                        <div className='management-header-item' style={{ width: width }}>공간명</div>
                        <div className='management-header-item'>지점명</div>
                        <div className='management-header-item'>적용</div>
                    </div>
                    {
                        props.districts && props.districts.map((district) => {
                            if (district.site_id !== props.currentSite.id) return;
                            return <DistrictManagementItem key={district.id} props={{ ...props, district, width, setWidth }} />
                        })
                    }
                    {
                        addNew && <AddDistrict props={{ ...props, setAddNew }} />
                    }
                </div>
            </div>
        }
    </>)
}