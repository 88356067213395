import React, { useState, useEffect, useRef } from 'react';
import {putSite, deleteSite} from '../../util/sites';

export default function SiteManagementItem(props) {
    const itemRef = useRef(null);
    const [name, setName] = useState(props.site.name);

    async function applyBtn() {
        try {
            const body = {
                id: props.site.id,
            };
            if (name !== props.site.name) { // 이름이 바뀌었다면
                body.name = name;
            }
            if (Object.keys(body).length === 1) {
                alert('변경할 내용이 없습니다.');
                return;
            }
            const message = await putSite(props.site.id , body);
            props.update();
            alert(message);
        } catch (e) {
            console.error(e);
        }
    }
    async function deleteBtn() {
        try {
            const message = await deleteSite(props.site.id);
            props.update();
            alert(message);
        } catch (e) {
            alert(e.response.data.message);
        }
    }

    useEffect(() => {
        if (itemRef.current.offsetWidth > props.width)
            props.setWidth(itemRef.current.offsetWidth);
        else {
            itemRef.current.style.width = `${props.width}px`;
        }
    }, [props.width]);


    return (<>
        <div className='management-body'>
            <div ref={itemRef} className='management-body-item'>
                <input type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
            </div>
            <div className='management-body-item'>
                <button className='management-body-item-button' onClick={applyBtn}>반영</button>
                <button className='management-body-item-delete-button' onClick={deleteBtn}>삭제</button>
            </div>
        </div>
    </>)
}