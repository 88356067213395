import React, { useEffect, useState } from 'react';
import InputSlide from './InputSlide';
import InputArrow from './InputArrow';

export default function AC({ props }) { // 에어컨용 모니터링 카드
    const [values, setValues] = useState({});

    async function update(name, value) {
        values[name].value = value;
        console.log('update');
        console.log(values);
    }

    useEffect(() => {
        if (props.device === undefined) {
            return;
        }
        const values = {};
        for (const value of props.device.values) {
            values[value.name] = value;
        }
        setValues(values);
    }, [props.device.values]);


    return (<>
        <div className='device-card'>
            <div className='device-card-wrapper-vertical'>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>{props.device.name}</div>
                </div>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>
                        <div className='img-ac' />
                    </div>
                </div>
            </div>
            {
                values['온도'] && <div className='device-card-data'><InputArrow device={props.device} value={values['온도']} label={'희망온도'} min={18} max={30} currentUser={props.currentUser} /></div>
            }
            <div className='device-card-wrapper-vertical'>
                <div className='device-card-data-wrapper'>
                    {
                        values['전원'] && <div className='device-card-data'>전원</div>
                    }
                    {
                        values['전원'] && <div className='device-card-data'><InputSlide device={props.device} value={values['전원']} currentUser={props.currentUser} /></div>
                    }
                </div>
            </div>
        </div>
    </>)
}