import React, { useEffect, useState } from 'react';
import DeviceCardInput from './DeviceCardInput';

export default function VirtualCommon({ props }) {

    const [values, setValues] = useState([]);

    useEffect(() => {
        const tempValues = [...props.device.values];

        tempValues.forEach((v) => {
            if (v.symbolic_id !== undefined) {
                props.devices.forEach((device) => {
                    device.values.forEach((value) => {
                        if (value.id === v.symbolic_id) {
                            v.value = value.value;
                            v.unit = value.unit;
                            v.device_id = value.device_id; // 진짜 value device_id로 변경
                            v.id = value.id; // 진짜 value ID로 변경
                        }
                    });
                });
            }
        });
        setValues(tempValues);
    }, [props.devices]);
    return (<>
        <div className='device-card'>
            <div className='device-card-wrapper-vertical'>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>{props.device.name}</div>
                </div>
                <div className='device-card-data-wrapper'>
                    <div className='device-card-data'>{props.device.type}</div>
                </div>
            </div>
            {
                values && values.sort((a, b) => a.name > b.name ? 1 : -1).map((value) => {
                    return <DeviceCardInput key={`${value.id}${value.value}`} value={value} device={props.device} />
                })
            }
        </div>
    </>)
}