import React, { useEffect } from 'react';

import SessionCheck from './SessionCheck';
import './Root.css';

export default function Root() {
    return (<>
        <SessionCheck />
        <div className='loading-wrapper'>
            <img src='/icon/cached_FILL0_wght400_GRAD0_opsz48.svg' alt='loading' className='loading-icon' />
        </div>
    </>)
}