import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import SiteManagementItem from './SiteManagementItem';
import AddSite from './AddSite';

import { getSites } from '../../util/sites';
import { getUsers } from '../../util/users';


export default function SiteManagement({ props }) {
    const [newSite, setNewSite] = useState(false); // 사이트 추가 창
    const [width, setWidth] = useState(0);

    async function update() {
        const sites = await getSites();
        if (sites !== undefined) {
            props.setSites(sites);
        }
        const users = await getUsers();
        if (users !== undefined) {
            props.setUsers(users);
        }
    }

    useEffect(() => {
        update();
    }, []);

    return (<>
        <div className='management-wrap'>
            <div className='management-add-button' onClick={() => { setNewSite(!newSite) }}>
                <p>{!newSite ? '지점 생성' : '지점 관리'}</p>
            </div>
            <div className='management'>
                <div className='management-header'>
                    <div className='management-header-item' style={{ width: width }} >이름</div>
                    <div className='management-header-item'>적용</div>
                </div>
                {
                    props.sites && props.sites.map((site) => {
                        return <SiteManagementItem key={site.id} site={site} update={update} users={props.users} width={width} setWidth={setWidth} />
                    })
                }
                {newSite && <AddSite users={props.users} update={update} width={width} setNewSite={setNewSite} />}
            </div>

        </div>
    </>)
}